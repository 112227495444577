import { SignIn } from "@clerk/remix";

export default function SignInPage() {
  return (
    <div className="h-full flex justify-center">
      <div className="mt-32">
        <SignIn />
      </div>
    </div>
  );
}
